import React from "react"
import config from "../../config"

import moment from "moment"
export default function Footer() {
  return (
    <footer id="footer" className="text-light">
      <ul className="footer-social-media vertical-collapse">
        {config.socialLinks.map((social) => {
          const { style, icon, name, url } = social
          return (
            <li key={url}>
              <a
                role="link"
                href={url}
                className={`icon ${style} ${icon}`}
                name={name}
              >
                <span className="sr-only">{name}</span>
              </a>
            </li>
          )
        })}
      </ul>
      <small style={{ textAlign: "center", width: "100%" }}>
        &copy;{`${moment().format("YYYY")} Tim Sandberg Music LLC`}
      </small>
    </footer>
  )
}
