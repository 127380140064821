/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import OuterLayout from "./OuterLayout"
import Footer from "./Footer"

const PageLayout = ({
  title,
  metaImage,
  metaDescription,
  children,
  contentPath,
  fullWidth = false,
}) => {
  return (
    <OuterLayout
      title={title}
      metaImage={metaImage}
      description={metaDescription}
      contentPath={contentPath}
    >
      <Header />
      <div
        className={`text-light ${
          fullWidth ? " container-fluid p-0" : " container p-3 p-sm-0"
        }`}
      >
        <main className="col-xs-12">{children}</main>
      </div>
      <Footer />
    </OuterLayout>
  )
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  contentPath: PropTypes.string.isRequired,
  metaImage: PropTypes.string,
  metaDescription: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  title: PropTypes.string,
  header: PropTypes.bool,
}

export default PageLayout
