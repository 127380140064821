import React from "react"
import PageLayout from "../components/PageLayout"
import PageHeader from "../components/PageHeader"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import Col from "react-bootstrap/Col"
import { Badge, Card, Row } from "react-bootstrap"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

const Contact = ({ data }) => {
  const testimonials = data.testies.nodes

  const timPic =
    "https://s3-us-west-2.amazonaws.com/timsandberg.com/tim_96_cropped.jpg"

  const services = [
    "Original soundtracks",
    "Songwriting",
    "Mixing",
    "Mastering",
    "Sound Design",
    "Audio repair",
    "Music/Audio consultation",
    "Arrangement",
    "3D audio experiences",
    "Mastering for streaming",
    "Try me!",
  ]

  const mediums = [
    "Feature films",
    "Short films",
    "Animation",
    "Web series",
    "Commercial/Corporate",
    "Audio tours",
    "Streaming platforms",
    "You name it",
  ]

  // eslint-disable-next-line react/prop-types
  const SectionHeader = ({ title }) => <p className="display-6">{title}</p>

  return (
    <PageLayout
      title={"Contact"}
      actionItem="follow"
      contentPath="contact"
      metaDescription="Contact page for reaching Tim Sandberg about your project"
    >
      <PageHeader title="Contact" />
      <Row className="g-5">
        <Col xs={12}>
          <div className="bg-dark p-4 rounded">
            <Row className="g-3">
              <Col md={6} lg={4}>
                <div
                  style={{ borderRadius: "50%", maxWidth: "350px", flex: 1 }}
                >
                  <StaticImage
                    src={timPic}
                    layout="constrained"
                    className="rounded"
                    alt="A picture of Tim laughing in the studio."
                  />
                </div>
              </Col>
              <Col md={6} className="ps-lg-3">
                <SectionHeader title="Let's connect" />
                <div className="copy fw-light">
                  <p>
                    All inquiries welcome. You can use the contact form{" "}
                    <a href="https://us7.list-manage.com/contact-form?u=1eaf7da4258b016fb95de0ab5&form_id=5a11262148d3e66f4fcc58e102516044">
                      here
                    </a>{" "}
                    or shoot me an email at <strong>tasandberg</strong> at{" "}
                    <strong>gmail</strong> dot com.
                  </p>
                  <p>
                    Working with me is a breeze. Drop me a line about your
                    project and we can begin working together to decide how
                    sound and music can help elevate your vision into an
                    unforgettable experience.
                  </p>
                </div>
                <a
                  className="btn btn-danger btn-lg mt-3"
                  target="_blank"
                  rel="noreferrer"
                  href="https://us7.list-manage.com/contact-form?u=1eaf7da4258b016fb95de0ab5&form_id=5a11262148d3e66f4fcc58e102516044"
                >
                  Contact Me
                </a>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={12}>
          <h2 className="font-bebas">Testimonials</h2>
          <Row className="g-3 g-lg-4">
            {testimonials.map((s) => (
              <Col key={s.id} style={{ minWidth: "350px" }}>
                <Card bg="dark" className="h-100 p-2 rounded">
                  <Card.Body>
                    <div
                      className="testimonial-body copy fw-light"
                      dangerouslySetInnerHTML={{
                        __html: s.testimonial.childMarkdownRemark.html,
                      }}
                    />
                    <div className="d-flex flex-row flex-wrap justify-content-end align-items-center">
                      <div className="d-flex flex-column align-items-end me-3 justify-content-center">
                        <a href={s.link} target="_blank" rel="noreferrer">
                          {s.name}
                        </a>
                        <small className="text-muted">{" " + s.jobTitle}</small>
                      </div>
                      <GatsbyImage
                        style={{ borderRadius: "50%" }}
                        image={getImage(s.pic)}
                        alt={`Picture of ${s.name}`}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <h2 className="font-bebas">Services</h2>
          <div>
            {services.map((s) => (
              <Badge
                key={`services-${s}`}
                className="fs-5 text-light  me-3 my-3"
                bg="secondary"
              >
                {s}
              </Badge>
            ))}
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="h-100">
            <h2 className="font-bebas">Mediums</h2>
            <div>
              {mediums.map((s) => (
                <Badge
                  key={`services-${s}`}
                  className="fs-5 text-light  me-3 my-3 bg-indigo"
                  style={{ backgroundColor: "#910505" }}
                >
                  {s}
                </Badge>
              ))}
            </div>
          </div>
        </Col>
        <Col xs={12} className="text-center">
          <a
            className="btn btn-danger btn-lg"
            target="_blank"
            rel="noreferrer"
            href="https://us7.list-manage.com/contact-form?u=1eaf7da4258b016fb95de0ab5&form_id=5a11262148d3e66f4fcc58e102516044"
          >
            Contact Tim
          </a>
        </Col>
      </Row>
      <Row></Row>
    </PageLayout>
  )
}
Contact.propTypes = {
  data: PropTypes.object.isRequired,
}
export default Contact

export const query = graphql`
  query Testimonials {
    testies: allContentfulTestimonial {
      nodes {
        id
        jobTitle
        link
        name
        testimonial {
          childMarkdownRemark {
            html
          }
        }
        accolades
        pic: headshot {
          gatsbyImageData(width: 50, height: 50)
        }
      }
    }
  }
`
