import React from "react"
import { PropTypes } from "prop-types"
import { Link } from "gatsby"
export const FollowButton = () => (
  <iframe
    title="follow button"
    src="https://open.spotify.com/follow/1/?uri=spotify:artist:0KBqxHAUpvEuj6DXUyYwJF&size=detail&theme=light&show-count=0"
    width="180"
    height="56"
    scrolling="no"
    frameBorder="0"
    allowtransparency="true"
  ></iframe>
)

const PageHeader = ({ title, flare = false, breadcrumb }) => (
  <h1 className="font-bebas h1 mt-4 mb-4" style={{ letterSpacing: 2 }}>
    {breadcrumb && (
      <Link style={{ opacity: "0.5" }} to={`/${breadcrumb.toLowerCase()}`}>
        {breadcrumb}
        {" / "}
      </Link>
    )}
    {title}
  </h1>
)

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  flare: PropTypes.bool,
  breadcrumb: PropTypes.string,
}

export default PageHeader
