module.exports = {
  siteTitle: "Tim Sandberg - Composer", // <title>
  manifestName: "Tim Sandberg",
  manifestShortName: "Landing", // max 12 characters
  manifestStartUrl: "/",
  manifestBackgroundColor: "#663399",
  manifestThemeColor: "#663399",
  manifestDisplay: "standalone",
  manifestIcon: "src/assets/img/favicon-16x16.png",
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: "Tim Sandberg",
  subHeading: "Music tailored to your vision",
  // social
  socialLinks: [
    {
      icon: "fab fa-soundcloud",
      name: "Soundcloud",
      url: "https://soundcloud.com/timsandbergmusic",
    },
    {
      icon: "fab fa-instagram",
      name: "Instagram",
      url: "https://www.instagram.com/timmehsnx/",
    },
    {
      icon: "fab fa-twitter",
      name: "Twitter",
      url: "https://twitter.com/TimSandberg",
      share: (url) => `https://twitter.com/intent/tweet?text=${url}`,
    },
    {
      icon: "fab fa-medium",
      name: "Medium",
      url: "https://medium.com/timsandbergmusic",
    },
    {
      icon: "fas fa-envelope",
      name: "Email",
      url: "mailto:tasandberg+website@gmail.com",
      share: (url) => `mailto:change@me.com?subject=Tim Sandberg&body=${url}`,
    },
    {
      icon: "fab fa-facebook",
      name: "Facebook",
      url: "https://www.facebook.com/timsandbergmusic",
      share: (url) => `https://www.facebook.com/sharer?u=${url}`,
    },
  ],
}
