import { Link } from "gatsby"
import React from "react"
import { Button } from "react-bootstrap"

const links = [
  {
    href: "/music",
    label: "Music",
  },
  {
    href: "/credits",
    label: "Credits",
  },
  {
    href: "/blog",
    label: "Blog",
  },
]

const Header = () => (
  <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
    <div className="container-fluid" style={{ maxWidth: "1500px" }}>
      <Link to="/">
        <h4 className="navbar-brand">Tim Sandberg</h4>
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse justify-content-end"
        id="navbarNavAltMarkup"
      >
        <div className="navbar-nav">
          {links.map((link, i) => (
            <Link
              className="nav-link text-end"
              activeClassName="active"
              key={`link-${i}`}
              to={link.href}
            >
              {link.label}
            </Link>
          ))}
          <Link
            className="nav-link text-end"
            activeClassName="active"
            to="/contact"
          >
            Hire
          </Link>
        </div>
      </div>
    </div>
  </nav>
)

export default Header
